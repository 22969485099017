//Play background video on client side
export function videoAutoplay() {
  document.addEventListener("DOMContentLoaded", () => {
    const videoBackgrounds = document.querySelectorAll("[data-video-background]");
    if (videoBackgrounds.length) {
      videoBackgrounds.forEach((video) => {
        if (!video.hasAttribute("autoplay")) {
          video.play();
        }
      });
    }
  });
}
