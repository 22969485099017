export function stickyHeader() {
  const headerEl = document.querySelector("[data-sticky-header]");
  if (headerEl) {
    function updateHeaderClass() {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 0) {
        headerEl.classList.add("main-header--not-top");
      } else {
        headerEl.classList.remove("main-header--not-top");
      }
    }

    if (document.readyState === "interactive") updateHeaderClass();

    window.addEventListener("scroll", updateHeaderClass);
  }
}
