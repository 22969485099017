import "../scss/style.scss";

import { accordion } from "./modules/accordion";
import { tabs } from "./modules/tabs";
import { overlayMenu } from "./modules/overlay-menu";
import { videoAutoplay } from "./modules/video-autoplay";
import { pdfLinks } from "./modules/pdf-links";
import { dropdown } from "./modules/dropdown";
import { stickyHeader } from "./modules/sticky-header";

pdfLinks();

dropdown();

videoAutoplay();

overlayMenu();

accordion();

tabs();

stickyHeader();
